import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56b67f30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "fullpage-loader-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UILoaderFullpage = _resolveComponent("UILoaderFullpage")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    (!_ctx.isGloballyLoading)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, { name: _ctx.transitionName }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_KeepAlive, { include: "Accounts" }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024))
                ]),
                _: 2
              }, 1032, ["name"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fadeLong" }, {
      default: _withCtx(() => [
        (_ctx.isGloballyLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_UILoaderFullpage, { class: "custom-loader" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}