import { CalendarRangePreset } from "./types/typesCalendarRange.interface";

interface numberStringObj {
  [key: number]: string;
}

export const constants = {
  millisecondsInADay: <number>1000 * 60 * 60 * 24,
  millisecondsInASecond: <number>1000,
  refreshIntervalInSeconds: <number>15,
  homepageRoute: <string>"/campaigns",
  //eslint-disable-next-line
  emailRegex: <RegExp>/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  monthTextByNumber: <numberStringObj>{
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  defaultStartDate: new Date("2018-01-01T00:00:00"),
  chartYScaleCutoff: <number>6,
  chartXScaleCutoffMobile: <number>7,
  chartXScaleCutoffDesktop: <number>14,
};

interface stringArrayObj {
  [key: string]: string[];
}

interface stringObj {
  [key: string]: string;
}

interface segmentMetadataObj {
  [key: string]: segmentMetadataObjProperties;
}

interface segmentMetadataObjProperties {
  label: string;
  color: string;
  colorHex: string;
}

export const segmentMetadata: segmentMetadataObj = {
  total: {
    label: "Total",
    color: "--vis-color0",
    colorHex: "#0091da",
  },
  newSegment: {
    label: "New",
    color: "--vis-color1",
    colorHex: "#cbabc5",
  },
  lapsed: {
    label: "Lapsed",
    color: "--vis-color2",
    colorHex: "#3ccbda",
  },
  loyal: {
    label: "Loyal",
    color: "--vis-color3",
    colorHex: "#74bcf9",
  },
  remainingTotal: {
    label: "Total",
    color: "--vis-color0",
    colorHex: "#0091da",
  },
};

export const campaignStatusDisplayText: stringObj = {
  ACTIVE: "active",
  EXPIRED: "completed",
};

// ensure these are sorted from most to least permissions required
export const rolePermissions: stringArrayObj = {
  "External User - Chase All User Columns": [
    "password.update",
    "advertiser.read",
    "user.read",
    "campaign.read",
    "report.read",
    "report.redemption.total_expenses",
    "report.redemption.redeemed_amount",
    "report.redemption.card_bin",
    "report.redemption.card_last_four",
    "report.redemption.return_on_ad_spend",
    "report.redemption.return_on_investment",
    "report.redemption.authorization_id",
    "report.redemption.merchant_name",
    "report.redemption.merchant_location",
    "report.redemption.merchant_id",
    "report.redemption.merchant_postal_code",
    "report.redemption.deal_start_week",
    "report.redemption.offer_column",
    "report.redemption.average_order_value",
    "report.redemption.billing_zip",
    "field.average_order_value.read",
    "field.return_on_ad_spend.read",
    "report.redemption.advertiser_name",
    "report.redemption.publisher_code",
    "report.redemption.redemption_date",
    "report.redemption.transaction_date",
    "report.redemption.transaction_amount",
    "report.redemption.figg_fee_columns",
    "report.redemption.date_bucket_columns",
    "report.redemption.total_redemptions",
    "feature.reports.custom_email_address.view",
    "report.type.campaign_performance",
    "report.type.monthly",
    "report.type.campaign_performance.jpmc",
    "report.type.monthly.jpmc",
    "report.redemption.impressions",
    "field.total_impressions.read",
    "field.total_serves.read",
    "report.redemption.unique_impressions",
  ],
  "External User - All report columns": [
    "password.update",
    "advertiser.read",
    "user.read",
    "campaign.read",
    "report.read",
    "report.redemption.total_expenses",
    "report.redemption.redeemed_amount",
    "report.redemption.card_bin",
    "report.redemption.card_last_four",
    "report.redemption.return_on_ad_spend",
    "report.redemption.return_on_investment",
    "report.redemption.authorization_id",
    "report.redemption.merchant_name",
    "report.redemption.merchant_location",
    "report.redemption.merchant_id",
    "report.redemption.merchant_postal_code",
    "report.redemption.deal_start_week",
    "report.redemption.offer_column",
    "report.redemption.average_order_value",
    "report.redemption.billing_zip",
    "field.average_order_value.read",
    "field.return_on_ad_spend.read",
    "report.redemption.advertiser_name",
    "report.redemption.publisher_code",
    "report.redemption.redemption_date",
    "report.redemption.transaction_date",
    "report.redemption.transaction_amount",
    "report.redemption.figg_fee_columns",
    "report.redemption.date_bucket_columns",
    "report.redemption.total_redemptions",
    "feature.reports.custom_email_address.view",
    "report.type.campaign_performance",
    "report.type.monthly",
    "report.type.campaign_performance.jpmc",
    "report.type.monthly.jpmc",
  ],
  "External User - Limited Access": [
    "password.update",
    "advertiser.read",
    "user.read",
    "campaign.read",
    "report.read",
    "report.redemption.total_expenses",
    "report.redemption.redeemed_amount",
    "report.redemption.card_bin",
    "report.redemption.card_last_four",
    "report.redemption.billing_zip",
    "report.redemption.advertiser_name",
    "report.redemption.publisher_code",
    "report.redemption.redemption_date",
    "report.redemption.transaction_date",
    "report.redemption.transaction_amount",
    "report.redemption.figg_fee_columns",
    "report.redemption.date_bucket_columns",
    "report.redemption.total_redemptions",
    "feature.reports.custom_email_address.view",
    "report.type.campaign_performance",
    "report.type.monthly",
    "report.type.campaign_performance.jpmc",
    "report.type.monthly.jpmc",
  ],
  "Admin User": [
    "password.update",
    "advertiser.read",
    "user.read",
    "user.create",
    "user.update",
    "permission.read",
    "admin.user.read",
    "campaign_details.read",
    "admin.advertiser.all",
    "user.delete",
    "feature.admin_tool",
  ],
  "External User": [
    "password.update",
    "advertiser.read",
    "user.read",
    "campaign.read",
    "report.read",
    "report.type.campaign_performance",
    "report.type.campaign_performance.jpmc",
    "report.type.monthly",
    "report.type.monthly.jpmc",
    "feature.reports.custom_email_address.view",
  ],
  "Admin User - View Only": [
    "permission.read",
    "admin.user.read",
    "admin.advertiser.all",
    "feature.admin_tool",
  ],
};

export const permissionDescriptions: stringObj = {
  "admin.user.read": "Allows admin user to get all user and admin user info.",
  "admin.advertiser.all": "Allows a user to see all advertisers in the system.",
  "password.update": "Allows user to update their password.",
  "user.create": "Allows user to create another user.",
  "user.update": "Allows user to update another user.",
  "user.read": "Allows user to get their own user info.",
  "user.delete": "Allows user to delete another user.",
  "advertiser.read": "Allows user to get Advertiser Info.",
  "campaign.read": "Allow user to get Campaigns Info.",
  "campaign_details.read": "Allows user to get Campaign Details.",
  "report.read": "Allows user to generate reports. ",
  "permission.read": "Allows admin user to read list of all permissions.",
  "role.read": "Allows admin user to read list of all roles.",
  "role.create": "Allows admin user to create a role.",
  "role.delete": "Allows admin user to delete a role.",
  "role.update": "Allows admin user to update a role.",
  "feature.reports.custom_email_address.view":
    "Allows the user to send reports to custom emails.",
  "feature.offer_details.view":
    "Allows the user to view the Campaign Details page within the UI.",
  "field.average_order_value.read":
    "Displays Average Order Value (AOV) within the UI.",
  "field.return_on_ad_spend.read":
    "Displays Return on Ad Spend (ROAS) within the UI.",
  "field.total_serves.read": "Displays Total Serves within the UI.",
  "field.total_impressions.read": "Displays Total Impressions within the UI.",
  "report.redemption.offer_column": "Toggle offer-column in reports.",
  "report.redemption.average_order_value":
    "Toggle average order value in reports.",
  "report.redemption.billing_zip": "Toggle average order value in reports.",
  "report.redemption.total_expenses":
    "Toggle total expenses column in reports.",
  "report.redemption.redeemed_amount":
    "Toggle redeemed amounts column in reports.",
  "report.redemption.card_bin": "Toggle card bin column in reports.",
  "report.redemption.card_last_four":
    "Toggle card last four column in reports.",
  "report.redemption.return_on_ad_spend": "Toggle roa column in reports.",
  "report.redemption.return_on_investment": "Toggle roi column in reports.",
  "report.redemption.authorization_id": "Toggle authorization id in reports",
  "report.redemption.merchant_name": "Toggle merchant name in reports.",
  "report.redemption.merchant_location": "Toggle merchant location in reports.",
  "report.redemption.merchant_id": "Toggle merchant id in reports.",
  "report.redemption.merchant_postal_code":
    "Toggle merchant postal code in reports.",
  "report.redemption.deal_start_week": "Toggle deal start week in reports.",
  "report.redemption.advertiser_name": "Toggle advertiser name in reports.",
  "report.redemption.publisher_code": "Toggle publisher code in reports.",
  "report.redemption.redemption_date": "Toggle redemption date in reports.",
  "report.redemption.transaction_date": "Toggle transaction date in reports.",
  "report.redemption.transaction_amount":
    "Toggle transaction amount in reports.",
  "report.redemption.figg_fee_columns": "Toggle Figg fee columns in reports.",
  "report.redemption.date_bucket_columns":
    "Toggle date bucket columns in reports.",
  "report.redemption.total_redemptions": "Toggle total redemptions in reports.",
  "report.type.campaign_performance":
    "Allows user to generate campaign performance report.",
  "report.type.monthly": "Allows user to generate monthly report.",
  "report.type.campaign_performance.jpmc":
    "Allows user to generate a campaign performance JPMC report.",
  "feature.admin_tool": "Allows a user access to the admin dashboard.",
};

export const getCalendarRangePresets = (): CalendarRangePreset[] => [
  {
    label: "Last 7 days",
    range: {
      start: new Date(new Date().setDate(new Date().getDate() - 7)),
      end: new Date(),
    },
  },
  {
    label: "Last 30 days",
    range: {
      start: new Date(new Date().setDate(new Date().getDate() - 30)),
      end: new Date(),
    },
  },
  {
    label: "Last 90 days",
    range: {
      start: new Date(new Date().setDate(new Date().getDate() - 90)),
      end: new Date(),
    },
  },
  {
    label: "Last 6 months",
    range: {
      start: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      end: new Date(),
    },
  },
  {
    label: "Last year",
    range: {
      start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      end: new Date(),
    },
  },
];
