import { constants } from "@/constants";
import {
  CalendarRange,
  CalendarRangePreset,
} from "./types/typesCalendarRange.interface";

// returns an integer number of days between two dates of the provided range
export const getDaysDiffNum = (
  input: CalendarRange,
  includeEndDate?: boolean
): number => {
  const date1 = new Date(input.start).getTime();
  const date2 = new Date(input.end).getTime();
  const diffTime = Math.abs(date2 - date1);
  return includeEndDate
    ? Math.floor(diffTime / constants.millisecondsInADay) + 1
    : Math.floor(diffTime / constants.millisecondsInADay);
};

export const daysDifference = (startDate: Date, endDate: Date): number => {
  const dateRangeObj: CalendarRange = {
    start: startDate,
    end: endDate,
  };
  return getDaysDiffNum(dateRangeObj);
};

export const getDaysRemainingOrPassedText = (
  startDate: Date,
  endDate: Date | null
): string => {
  if (endDate) {
    return `${
      daysDifference(new Date(), endDate).toLocaleString() +
      " day" +
      (daysDifference(new Date(), endDate) !== 1 ? "s" : "") +
      " remaining"
    }`;
  } else {
    return `Running ${
      daysDifference(startDate, new Date()).toLocaleString() +
      " day" +
      (daysDifference(startDate, new Date()) !== 1 ? "s" : "")
    }`;
  }
};

// returns a string in the form of 'mm/dd/yyyy'
export const getMMDDYYYYDateString = (date: Date): string => {
  return new Date(date).toLocaleDateString("es-pa", { timeZone: "UTC" });
};

// returns a string in the form of 'yyyy-mm-dd'
export const getStringFromDate = (date: Date): string => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
};

export const getDateFromString = (date: string): Date => {
  return new Date(date + "T00:00:00");
};

// returns a string in the form of 'mm/dd' or 'yy/mm', depending on time unit provided
export const getChartDateLabel = (
  value: Date,
  timeUnit: "DAY" | "WEEK" | "FORTNIGHT" | "MONTH"
): string => {
  const s: string[] = getMMDDYYYYDateString(value).split("/");
  let labelStrings: string[] = [];
  // returns an array of string for MM, DD, and YYYY for parsing
  if (timeUnit === "DAY" || timeUnit === "WEEK" || timeUnit === "FORTNIGHT") {
    labelStrings.push(s[0]);
    labelStrings.push(s[1]);
  } else if (timeUnit === "MONTH") {
    labelStrings.push(s[0]);
    labelStrings.push(s[2].substring(s.length - 1, s.length + 1));
  } else {
    labelStrings = s;
  }
  return labelStrings.join("/");
};

export const isArrayContainedInArray = (
  bigArray: string[],
  smallArray: string[]
) => {
  return smallArray.every((v) => bigArray.includes(v));
};

export const openGlobalModal = (): void => {
  const dialog = document.getElementById("global-dialog") as HTMLDialogElement;
  if (dialog) {
    dialog.showModal();
    dialog.scroll(0, 0);
  }
};

export const closeGlobalModal = (): void => {
  const dialog = document.getElementById("global-dialog") as HTMLDialogElement;
  if (dialog) {
    dialog.close();
  }
};

export const parseJSON = (inputString: string): any | null => {
  if (inputString) {
    try {
      return JSON.parse(inputString);
    } catch (e) {
      return null;
    }
  }
};

export const getPresetArrayFromPresetString = (
  presetString: string
): CalendarRangePreset[] | null => {
  const presetStrings: string[] = parseJSON(presetString);
  if (Array.isArray(presetStrings)) {
    const newPresets: any = [];
    presetStrings.forEach((string: string) => {
      let newPreset = {} as CalendarRangePreset;
      newPreset.label = string;
      const spacesArray = string.split(" "),
        numberValue: number = parseInt(spacesArray[1]);
      if (string.includes("day")) {
        newPreset!.range!.start = new Date(
          new Date().setDate(new Date().getDate() - numberValue)
        );
      } else if (string.includes("month")) {
        newPreset!.range!.start = new Date(
          new Date().setMonth(new Date().getMonth() - numberValue)
        );
      } else if (string.includes("year")) {
        newPreset!.range!.start = new Date(
          new Date().setFullYear(new Date().getFullYear() - numberValue)
        );
      } else {
        newPreset = {
          label: string,
          range: {
            start: new Date(),
            end: new Date(),
          },
        };
      }
      newPresets.push(newPreset);
    });
    return newPresets;
  } else {
    console.log(
      "Could not properly parse calendar preset array from .ENV (VUE_APP_DATE_RANGE_PRESETS). Please supply a stringified array of strings, with each string in this format: Last 3 days, Last 3 months, Last 3 years"
    );
    return null;
  }
};
