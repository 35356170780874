import { defineStore } from "pinia";
import { StoreContent } from "../types/typesStoreContent.interface";
import { Advertiser } from "../types/typesAdvertisers.interface";

export const useContentStore = defineStore("content", {
  state: (): StoreContent => ({
    activeAdvertiser: null,
    availableAdvertisers: null,
  }),
  actions: {
    setActiveAdvertiser(newAdv: Advertiser) {
      this.$state.activeAdvertiser = newAdv;
    },
    setAvailableAdvertisers(newAdvertisers: Advertiser[]) {
      this.$state.availableAdvertisers = newAdvertisers;
    },
    clearContentState() {
      this.$state = <StoreContent>{
        activeAdvertiser: null,
        availableAdvertisers: null,
      };
    },
  },
});
