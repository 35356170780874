
export default {
  name: "UIButton",
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: "normal",
    },
  },
};
