import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/index";
import { globalFilters } from "./filters";
import { initPendo } from "@/flows/pendo";

const app = createApp(App);

app.config.globalProperties.$filters = globalFilters;

app.use(createPinia()).use(router).mount("#app");

initPendo();
