import { RouteRecordRaw, createWebHistory, createRouter } from "vue-router";
import { useUserStore } from "../store/user";
import { useContentStore } from "../store/content";
import { useAccessStore } from "../store/access";
import { constants } from "@/constants";
import { isArrayContainedInArray } from "@/utils";
import Cookies from "js-cookie";
import { logInUser } from "@/services/serviceLogin";
import { hydrateUser } from "@/flows/hydrateUser";
import { hydrateAccess } from "@/flows/hydrateAccess";
import { hydrateAdvertisers } from "@/flows/hydrateAdvertisers";
import { setPendo } from "@/flows/pendo";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/campaigns",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresAuth: false,
      order: 0,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
    meta: {
      requiresAuth: true,
      requiresPermissions: ["feature.admin_tool"],
      order: 1,
    },
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
      order: 2,
    },
  },
  {
    path: "/terms",
    name: "Terms and Conditions",
    component: () => import("../views/Terms.vue"),
    meta: {
      requiresAuth: false,
      order: 3,
    },
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: () => import("../views/Accounts.vue"),
    meta: {
      requiresAuth: true,
      order: 4,
    },
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresAdvId: true,
      order: 5,
    },
  },
  {
    path: "/campaigns/:campaignId(\\d+)",
    name: "Campaign Details",
    component: () => import("../views/CampaignDetails.vue"),
    meta: {
      requiresAuth: true,
      requiresAdvId: true,
      requiresPermissions: ["feature.offer_details.view"],
      order: 6,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/Reports.vue"),
    meta: {
      requiresAuth: true,
      requiresAdvId: true,
      order: 7,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      order: 8,
    },
  },
  {
    path: "/changepassword",
    name: "Change Password",
    component: () => import("../views/ChangePassword.vue"),
    meta: {
      requiresAuth: false,
      order: 9,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views/Support.vue"),
    meta: {
      requiresAuth: false,
      order: 10,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: constants.homepageRoute,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const storeUser = useUserStore(),
    storeContent = useContentStore(),
    storeAccess = useAccessStore();
  if (!storeUser.isLoggedIn) {
    if (Cookies.get("fadRefresh")) {
      storeAccess.setIsGloballyLoading(true);
      logInUser().then((response) => {
        if (response.success) {
          hydrateAccess(response.data);
          hydrateUser(response.data);
          hydrateAdvertisers(response.data).then((response) => {
            storeAccess.setIsGloballyLoading(false);
            setPendo();
            if (response.success && to.path === "/login") {
              next({ name: "Dashboard" });
            } else if (
              (!response.success && to.path === "/login") ||
              (to.meta.requiresAdvId && !storeContent.activeAdvertiser)
            ) {
              next({ name: "Accounts" });
            } else {
              to.meta.requiresPermissions &&
              !isArrayContainedInArray(
                storeAccess.getPermissionsArray,
                to.meta.requiresPermissions
              )
                ? next({ path: constants.homepageRoute })
                : next();
            }
          });
        } else {
          storeAccess.setIsGloballyLoading(false);
          Cookies.remove("fadRefresh");
          Cookies.remove("fadAdvSelect");
          console.log("Auto-login failed or token expired: " + response.data);
          if (to.meta.requiresAuth) {
            next({ name: "Login" });
          } else if (to.path === "/login") {
            next({ name: "Dashboard" });
          } else {
            to.meta.requiresPermissions &&
            !isArrayContainedInArray(
              storeAccess.getPermissionsArray,
              to.meta.requiresPermissions
            )
              ? next({ path: constants.homepageRoute })
              : next();
          }
        }
      });
    } else if (to.meta.requiresAuth) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (
    to.meta.requiresPermissions &&
    !isArrayContainedInArray(
      storeAccess.getPermissionsArray,
      to.meta.requiresPermissions
    )
  ) {
    next({ path: constants.homepageRoute });
  } else {
    next();
  }
});

export default router;
